<template>
  <div :class="$style.wrap">
    <Back />
    <div :class="$style.content">
      <img :class="$style.banner" :src="detail.image_url" />
      <h1 :class="$style.name">{{ detail.name }}</h1>
      <div :class="$style.author">
        <div>
          <img :class="$style.avatar" :src="detail.headimg" />
          <span>{{ detail.artist_name }}</span>
        </div>
        <span :class="$style.time">{{ createdAt }}</span>
      </div>
      <h2 :class="$style.title">产品介绍</h2>
      <p :class="$style.introduction">
        {{ detail.introduction }}
      </p>
      <van-collapse
        :class="$style.explain"
        :border="false"
        v-model="activeCollapse"
      >
        <van-collapse-item title="数字收藏品说明" name="1">
          <div :class="$style.iconWrap">
            <span :class="$style.icon">唯一编号</span>
            <span :class="$style.icon">可信记录</span>
            <span :class="$style.icon">永久存证</span>
            <span :class="$style.icon">不可修改</span>
          </div>
          <p :class="$style.explainContent">
            在区块链技术的支持下，绘画、视频、模型、门票等任何种类的数字商品都可以被确权，即拥有了一张专属自己的“数字身份证”，这个独一无二的数据内容会永久的保存在区块链上，让数字商品可以被拥有并承载其独特的价值。
            数字收藏品是基于区块链技术协议发行的数字商品，每个数字收藏品在
            区块链技术上都拥有唯
            一的标识，永久存储并且同样拥有不可篡改、不可分割的特性。区块链技术为数字收藏品的发行提供区块链底层技术支持，为平台提供可信存证扩展、数字商品确权技术。
            注意事项 *请先完成实名认证，再进行购买
            *因为数字产品的特殊性，不能算改，一旦销售不支持退换
          </p>
        </van-collapse-item>
      </van-collapse>
      <h2 :class="$style.title">作家其他作品</h2>
      <van-list
        v-if="detail.artist_id"
        :class="$style.list"
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <WorksCard
          :class="$style.listItem"
          v-for="item in list"
          :key="item.id"
          :info="item"
        />
      </van-list>
    </div>
    <Pay
      v-if="isShowBuy && Number(detail.price) !== 0"
      :stock="isStock"
      :price="detail.price"
      @handlePay="handlePay"
    />
    <ActionSheet v-if="isShowActions" :info="detail" path="cloth" />
  </div>
</template>

<script>
import Back from "@/components/Back";
import WorksCard from "@/components/WorksCard";
import Pay from "@/components/Pay";
import ActionSheet from "@/components/ActionSheet";
import { fetchArtistDdcDetail, fetchArtistDdcList } from "@/api";
export default {
  components: {
    Back,
    WorksCard,
    Pay,
    ActionSheet,
  },
  data() {
    return {
      activeCollapse: [],
      detail: {},
      id: this.$route.params.id,
      list: [],
      loading: false,
      finished: false,
      error: false,
      pageSize: 15,
      page: 1,
      total: 0,
    };
  },
  computed: {
    createdAt() {
      return this.detail.created_at?.split(" ")[0];
    },
    // is_paid=1, is_display_buy_button = 0, 展示分享/赠送
    // is_paid=0, is_display_buy_button=0 什么都不展示
    // is_paid=1, is_display_buy_button=1 展示分享/赠送
    // is_paid=0, is_display_buy_button=1 展示购买按钮
    isShowBuy() {
      const { is_paid, is_display_buy_button } = this.detail;
      return Number(is_paid) === 0 && Number(is_display_buy_button) === 1;
    },
    isShowActions() {
      // return true;
      const { is_paid } = this.detail;
      return Number(is_paid) === 1;
    },
    // 是否有库存
    isStock() {
      const { stock } = this.detail;
      return Number(stock) !== 0;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      const data = { ddc_id: this.id };
      fetchArtistDdcDetail(data).then((res) => {
        this.detail = res;
      });
    },
    getList() {
      const data = {
        artist_id: this.detail.artist_id,
        page: this.page,
        page_size: this.pageSize,
      };
      fetchArtistDdcList(data)
        .then((res) => {
          const { list, total, page } = res;
          // 过滤掉当前的作品
          const filterList = list.filter(
            (item) => Number(item.id) !== Number(this.id)
          );
          this.total = total;
          this.list = [...this.list, ...filterList];
          this.loading = false;
          this.page = page + 1;
          if (this.list.length >= this.total - 1) {
            this.finished = true;
          }
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },
    onLoad() {
      this.getList();
    },
    handlePay() {
      this.$router.push({
        path: "/pay",
        query: { ddc_id: this.id, price: this.detail.price },
      });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  .content {
    padding: 22px 32px 78px;
    .banner {
      width: 311px;
      height: 311px;
      border-radius: 24px;
    }
    .name {
      margin: 20px 0;
      font-size: 24px;
      line-height: 36px;
    }
    .author {
      height: 48px;
      background: #212121;
      border-radius: 11px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 18px;
      font-size: 14px;
      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 8px;
        vertical-align: middle;
      }
      .time {
        color: #8e8e95;
      }
    }
    .title {
      font-size: 14px;
      line-height: 20px;
      margin: 24px 0 8px 0;
    }
    .introduction {
      font-size: 10px;
      color: #8e8e95;
      line-height: 18px;
      margin-bottom: 24px;
    }
    .list {
      padding-top: 8px;
      .listItem {
        margin-bottom: 27px;
      }
    }
    .explain {
      :global {
        .van-collapse-item__title {
          display: flex;
          align-items: center;
        }
      }
      .iconWrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;
        .icon {
          width: 52px;
          height: 52px;
          line-height: 52px;
          border: 1px solid #8e8e95;
          border-radius: 50%;
          text-align: center;
        }
      }
      .explainContent {
        line-height: 18px;
      }
    }
    :global {
      .van-collapse-item {
        background: #212121;
        border-radius: 11px;
      }
      .van-collapse-item__title {
        background: #212121;
        border-radius: 11px;
        height: 48px;
        font-size: 14px;
        color: #fff;
      }
      .van-collapse-item__title--expanded::after {
        display: none;
      }
      .van-collapse-item__content {
        background: #212121;
        font-size: 10px;
        color: #8e8e95;
        border-radius: 11px;
      }
    }
  }
}
</style>
