// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WorksCard_wrap_pLH3V{height:9.38667rem;background:#212121;display:flex;flex-direction:column}.WorksCard_wrap_pLH3V,.WorksCard_wrap_pLH3V .WorksCard_img_iKeiM{border-radius:.8rem}.WorksCard_wrap_pLH3V .WorksCard_img_iKeiM{width:8.29333rem;height:8.29333rem}.WorksCard_wrap_pLH3V .WorksCard_info__UTNB{flex-grow:1;display:flex;justify-content:space-between;align-items:center;padding:0 .53333rem;font-size:.32rem;font-weight:500;color:#8e8e95}.WorksCard_wrap_pLH3V .WorksCard_info__UTNB .WorksCard_price_14TKQ{font-size:.42667rem;font-weight:600;color:#fff;margin-left:.26667rem}", ""]);
// Exports
exports.locals = {
	"wrap": "WorksCard_wrap_pLH3V",
	"img": "WorksCard_img_iKeiM",
	"info": "WorksCard_info__UTNB",
	"price": "WorksCard_price_14TKQ"
};
module.exports = exports;
