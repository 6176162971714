<template>
  <div :class="$style.wrap" @click="handle2Detail">
    <img :class="$style.img" :src="info.image_url" />
    <div :class="$style.info">
      <span class="ellipsis">{{ info.name }}</span>
      <span v-if="Number(info.price) !== 0" :class="$style.price"
        >¥{{ info.price }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    handle2Detail() {
      // window.location.href = `/cloth/detail/${this.info.ddc_no}`;
      window.location.href = `/cloth/detail/${this.info.id}`;
      // this.$router.push({
      //   path: `/cloth/detail/${this.info.value}`,
      // });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  height: 352px;
  border-radius: 30px;
  background: #212121;
  display: flex;
  flex-direction: column;
  .img {
    width: 311px;
    height: 311px;
    border-radius: 30px;
  }
  .info {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-size: 12px;
    font-weight: 500;
    color: #8e8e95;
    .price {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      margin-left: 10px;
    }
  }
}
</style>
